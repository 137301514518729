import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Trans} from "react-i18next";
import AppointButton from "../components/appoint-button";
export const query = graphql`
  {
    absenceNotice {
      text
      active
      titel
    }
  }`

export default class IndexPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Layout>
                <SEO title="Home"/>
                <div className="container">
                    {this.props.data.absenceNotice.active &&
                        <div className="alert alert-info" role="alert">
                            <h4 className="alert-heading"><i className="fas fa-info-circle"></i> {this.props.data.absenceNotice.titel}</h4>
                            <p>
                                <div dangerouslySetInnerHTML={{__html:this.props.data.absenceNotice.text}}/>
                            </p>
                        </div>
                    }
                    <div className="row justify-content-center">
                        <div className="col-lg-8 align-content-center text-center">
                            <AppointButton/>
                            <br/>
                            <br/>
                        </div>
                        <div className="col-lg-8 text-center">
                            <div className="title">
                                <div className="">
                                    <h1 className="text-uppercase font-weight-bold"><Trans>Herzlich willkommen</Trans>
                                    </h1>
                                </div>
                                <div className="">
                                    <p className="font-weight-bolder mb-5"><Trans>in der Hausarztpraxis <br/> Dr. med.
                                        Elke
                                        Natascha Heinitz in
                                        Grube</Trans></p>

                                </div>
                            </div>

                            <p>
                                Wir sind eine normale Hausarzt- und Lehrpraxis. Bei uns könnt Ihr/können Sie
                                kardiologische (Ergometrie, Langzeitblutdruck), pneumologische (Lufu), internistische
                                (Diabetesschulung ohne Insulin) und sonstige Basisuntersuchungen und -therapien
                                durchführen lassen. Außerdem bieten wir beispielsweise Kinderuntersuchungen (U-Heft,
                                Impfungen, Jugendarbeitsschutzuntersuchungen), Unterstützung bei Kur-, Reha- und
                                Rentenanträgen an.
                                <br/><br/>
                                Neue Patient*innen können wir zur Zeit nicht aufnehmen.
                                <br/>
                                <a href={"/links"}>Daher bieten wir hier erste Anregungen zur weiteren Versorgung.</a>
                                <br/><br/>
                                Ausschließlich für unsere Bestandspatient*innen bieten wir ein &nbsp;
                                <a href={"https://order.medidate.org/?pid=7d704ae9-388e-464c-ac2b-c6fa928cf9c0&ptok=596e394243754c5051633669373978736151646337326672774a755444704b7657302f61555648796471656f794d2f7a79747530722f6e7a657842707a7253505449586947674c6f65316a423376744e6152485a4a46473552564436476f72616f553765446a574545786c64626e75726831496e6f537141584251446a556e6c66666c52776f6a622b46525143466e38314a3649522f476f7236776c34674130674472737133304f79774d3d"}>Terminbuchungssystem</a> an.
                                <br/><br/>
                                Im <a href={"/checkin"}>Check-In</a> findet Ihr/finden Sie Fragebögen zu verschiedenen
                                Themenbereichen.
                                Bitte sucht Euch/suchen Sie sich das passende Dokument heraus und füllt/füllen
                                dieses in Ruhe zuhause komplett aus. Das Dokument wird nach der persönlichen
                                Unterschrift automatisch an die Praxis gesendet.
                                <br/><br/>
                                Wir freuen uns Euch/Sie herzlich in der Praxis begrüßen zu dürfen.
                                <br/><br/>
                                Euer/Ihr Praxisteam
                                <br/>
                                Dr. med. Elke Natascha Heinitz
                            </p>

                        </div>
                    </div>
                </div>

            </Layout>
        )
    }
}
