import React from "react"

import DsgvoModal from "./dsgvo-modal";
import {Trans} from "react-i18next";

function AppointButton(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <a href={"#"} onClick={(e) => {
                e.preventDefault();
                setModalShow(true);
            }} className="btn btn-primary btn-xl js-scroll-trigger" target={"_blank"}
               ><Trans>  Termin vereinbaren</Trans></a>
            <DsgvoModal action={()=>{
                window.open("https://order.medidate.org/?pid=7d704ae9-388e-464c-ac2b-c6fa928cf9c0&ptok=596e394243754c5051633669373978736151646337326672774a755444704b7657302f61555648796471656f794d2f7a79747530722f6e7a657842707a7253505449586947674c6f65316a423376744e6152485a4a46473552564436476f72616f553765446a574545786c64626e75726831496e6f537141584251446a556e6c66666c52776f6a622b46525143466e38314a3649522f476f7236776c34674130674472737133304f79774d3d");
            }} show={modalShow} onHide={()=>{setModalShow(false)}}></DsgvoModal>
        </>
    );
}
export default AppointButton